@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}
